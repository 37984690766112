.hidden {
  display: none;
}

.share-button svg {
  height: 30px;
  width: 30px;
  fill: #0091EB;
  transition: fill 0.5s;
}

.share-button svg:hover {
  fill: #b34f36;
}

.share-button:focus svg {
  fill: #b34f36;
}

.share-button {
  color: #000;
  border: none;
}