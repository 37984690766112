.hidden {
  display: none;
}

.delete-button svg {
  height: 20px;
  width: 20px;
  fill: #0091EB;
  transition: fill 0.5s;
}

.delete-button svg:hover {
  fill: #FF3366;
}

.delete-button:focus svg{
  fill: #FF3366;
}

.delete-button {
  color: #000;
  border: none;
}