.thought-card {
  box-shadow: 1px 1px 10px rgb(214, 214, 214);
  margin: 25px 0px 0px 0px;
  border-radius: 15px;
  max-width: 400px;
  padding: 0px 20px 10px 20px;
  width: 100%;
  
  transition: height 2s, box-shadow 0.5s;
  animation: thoughtExist;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  -webkit-animation: thoughtExist;
  -webkit-animation-duration: 1s;
}

.all-thoughts .thought-card:hover {
  box-shadow: 3px 3px 20px rgb(179, 178, 178);
}

.thought-card .button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.thought-card > .thought-form {
  padding: 16px 0px 10px 0px !important;
  margin: 0;
  box-shadow: none;
  box-sizing: border-box;
}

.copied-notif {
  margin: 0;
  margin-top: 4px;
  margin-right: auto;
  color: #28ACFF;
  animation-name: copied;
  animation-duration: 3s;
  -webkit-animation-name: copied;
  -webkit-animation-duration: 3s;
}

.thought-card p {
  word-break: break-word;
}