@import-normalize;

@font-face {
  font-family: 'Nexa';
  src: local('Nexa'), url(./fonts/NexaDemo-Bold.woff) format('woff');
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
}

h1, h2 {
  font-family: 'Nexa', 'Merriweather Sans', sans-serif;
}

h1 {
  font-size: 55px;
  color: #0091EB;
}

h2 {
  font-size: 30px;
}

a:visited {
  color: #28acff
}

a {
  color: #28acff
}

button {
  background-color: #fff;
}

button:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}