.hidden {
  display: none;
}

.edit-button svg {
  height: 20px;
  width: 20px;
  fill: #0091EB;
  transition: fill 0.5s;
}

.edit-button svg:hover {
  fill: rgb(15, 79, 189);
}

.edit-button:focus svg {
  fill: rgb(15, 79, 189);
}

.edit-button {
  color: #000;
  border: none;
}