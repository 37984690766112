h1 > a{
  color: #0091EB !important;
}

.banner {
  text-align: center;
  color: #28ACFF;
  
  animation-name: fadeIn;
  animation-duration: 2s;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 2s;
}

.banner a {
  text-decoration: none;
}

.banner p {
  color: #0078C2;
}

.brainbox {
  padding: 30px;
  height: 200px;
  opacity: 0%;
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
}