main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-to-action {
  text-align: center;
  background-color: #008AE0;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23001f54' fill-opacity='0.15'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 100%;
  padding: 70px 0px 70px 0px;
  color: #fff;
}

.call-to-action button {
  background-color: #fff;
  color: #000;
  padding: 15px;
  box-shadow: 1px 1px 10px #2188c9;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  margin-top: 15px;
  transition: background-color 0.5s, color 0.5s;
}

.call-to-action button:focus {
  outline: #28acff;
}

.call-to-action button:hover {
  background-color: rgb(5, 9, 20);
  color: #fff;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  color: #0078C2;
  padding-top: 20px;
}

.main-footer {
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  margin-top: 500px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0074BD;
}

.thought-bubble {
  margin-left: 3px;
}