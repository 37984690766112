.edit-thought-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.edit-thought-button-wrapper button {
  margin-left: 0 !important;
}

.error {
  height: 20px;
}

.cancel:hover {
  background-color: #FF3366 !important;
  color: #fff !important;
}

.edit-thought-form {
  animation-name: exist;
  animation-duration: 2s;
  -webkit-animation-name: exist;
  -webkit-animation-duration: 2s;
}