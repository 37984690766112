.thought-form {
  box-shadow: 1px 1px 10px rgb(214, 214, 214);
  margin: 25px 0px 0px 0px;
  border-radius: 15px;
  max-width: 400px;
  padding: 20px 20px 20px 20px;
  width: 100%;
}

.thought-form textarea {
  width: 100%;
  min-height: 230px;
  resize: none;
  border: none;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.65px;
  box-sizing: border-box;
  overflow: hidden;
}

.thought-form textarea:focus {
  outline: none;
}

.thought-form button {
  background-color: rgb(5, 9, 20);
  color: #fff;
  padding: 15px;
  box-shadow: 1px 1px 10px #d8d8d8;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  margin-left: auto;
  display: block;
  transition: background-color 0.5s, color 0.5s;
}

.thought-form button:hover {
  background-color: #fff;
  color: rgb(5, 9, 20);
  box-shadow: 1px 1px 10px #c2c2c2;
}

.thought-form button:focus {
  background-color: #fff;
  color: rgb(5, 9, 20);
  box-shadow: 1px 1px 10px #c2c2c2;
}

.error {
  height: 20px;
  color: #4e96c2;
  text-align: center;
}

#create-new-thought {
  min-height: 100px !important
}